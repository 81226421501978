@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

.App{
  display: flex;
  flex-direction: column;
  background: #00000A;
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
  outline: none;
  color: white;
}

a:hover{
  color: #D82D18;
}